import React from "react"
import classes from "./AccordionTextItem.module.scss"
import SvgIcon from "src/components/SvgIcon"
import Plus from "src/assets/icons/plus.svg"
import Minus from "src/assets/icons/minus.svg"
import PropTypes from "prop-types"

const AccordionTextItem = ({ list, active, onToggle, isBoldText }) => {
  const { title, text } = list

  return (
    <li
      className={`${classes.accordionItem} ${active ? classes.active : ""}`}
      onClick={onToggle}
    >
      <div className={`${classes.accordionHeader}`}>
        <h4 className={isBoldText ? classes.itemTitleBold : classes.itemTitle}>
          {title}
        </h4>
        <button aria-label="Open Section" className={classes.controls}>
          <SvgIcon icon={active ? Minus : Plus} className="accent-color" />
        </button>
      </div>
      <div className={`${classes.itemText} ${active ? classes.open : ""}`}>
        <p className={classes.text}>{text}</p>
      </div>
    </li>
  )
}

AccordionTextItem.propTypes = {
  title: PropTypes.string,
  list: PropTypes.object,
  isBoldText: PropTypes.bool,
}

AccordionTextItem.defaultProps = {
  isBoldText: true,
}

export default AccordionTextItem
