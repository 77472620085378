import React, { memo } from "react"
import MultiCarousel from "../MultiCarousel/MultiCarousel"
import OurWorkCarouselItem from "./OurWorkCarouselItem/OurWorkCarouselItem"
import { useCasesQuery } from "src/hooks/useCasesQuery"

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 2000 },
    items: 2,
    slidesToSlide: 1,
  },
  desktop: {
    breakpoint: { max: 2000, min: 1024 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 1024, min: 577 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 576, min: 0 },
    items: 1,
  },
}

const OurWorksCarousel = ({ MultiCarouselClassName, className }) => {
  const { nodes } = useCasesQuery()
  return (
    <div className={className}>
      <MultiCarousel
        setup={responsive}
        sectionTitle={"Our Works"}
        className={MultiCarouselClassName}
        buttonGroup={true}
      >
        {nodes.map((item, index) => (
          <OurWorkCarouselItem
            key={index}
            image={item.screenPreview}
            title={item.title}
            description={item.pageContent.mainText[0].firstP}
            link={`/portfolio/${item.name}`}
            tags={item.mainTechnology}
          />
        ))}
      </MultiCarousel>
    </div>
  )
}

export default memo(OurWorksCarousel)
