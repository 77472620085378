import React from "react"
import classes from "./OurWorksCarouselItem.module.scss"
import Image from "../../../image"
import { Link } from "gatsby"
import SvgIcon from "../../../SvgIcon"
import Dotes from "../../../../assets/icons/whiteDotes.svg"

const CarouselItem = ({ image, description, title, link, tags }) => {
  return (
    <Link to={link} className={classes.carouselItem}>
      <Image
        src={image}
        alt={title}
        className={classes.image}
        draggable={false}
      />
      <div className={classes.desktopContent}>
        <div>
          <h4 className={classes.desktopTitle}>{title} </h4>
          <p className={classes.desktopText}> {description} </p>
        </div>
        <div>
          {tags.map((item, index) => {
            if (index < 3) {
              return (
                <span className={classes.desktopTag} key={item.id}>
                  {item.title}
                </span>
              )
            }
            if (index === 3) {
              return (
                <SvgIcon icon={Dotes} className={classes.dotes} key={item.id} />
              )
            }
          })}
        </div>
      </div>
      <div className={classes.mobileContent}>
        <h4 className={classes.title}>{title}</h4>
        <p className={classes.description}>{description}</p>
        <div className={classes.tag}>Web Application</div>
      </div>
    </Link>
  )
}

export default CarouselItem
